import React from 'react';
import {FeaturesSection,FeaturesLeft,FeaturesRight,FeaturesCard,
FeaturesCardImg,FeaturesLeftBtn,FeaturesRow
} from './products.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import { navigate } from "gatsby"
import ButtonDropDown from '../ButtonDropDown';


const Products = ({sharedData, data, location}) => {
    const color = data.BackgroundGrey ? "#f9fafc" : "#FFFFFF";


    const clickNavi = (route) => {
        route ? navigate(route) :  navigate("/");
    }


    return (
        <FeaturesSection id="productsContainer" style={{background: color}}>
            <Container>
                <FeaturesRow>
                    <Col md="12" lg="4">
                        <FeaturesLeft>
                            <Fade left delay={1*data.DelayConstant}>
                            <h3>{data.ProductsLeftHeading}</h3>
                            </Fade>
                            <Fade left delay={2*data.DelayConstant}>
                            <p>{data.ProductsLeftPara}</p>
                            </Fade>
                            <Fade left delay={3*data.DelayConstant}>
                                <React.Fragment>
                                    { data.ProductsLeftBtn ? (data.ProductsLeftBtn.MultiSelection ?  (
                                            <ButtonDropDown data={data.ProductsLeftBtn} />
                                        ) :  (
                                            <FeaturesLeftBtn href={data.ProductsLeftBtn.Items[0].Href} target="_blank">{data.ProductsLeftBtn.Title}</FeaturesLeftBtn>
                                        )): null
                                    }
                                </React.Fragment>
                            </Fade>
                        </FeaturesLeft>
                    </Col>
                    <Col md="12" lg="8">
                        <FeaturesRight>
                            <Row>
                            {
                                sharedData.Items.map((item,idx) => {
                                return <Col lg="6" md="6" sm="12">
                                        <Fade right delay={(idx+1)*data.DelayConstant}>
                                        <FeaturesCard  onClick={() => clickNavi(item.ProductRoute)}>
                                            <FeaturesCardImg>
                                                <img src={item.ProductsCardIcon} alt=""/>
                                            </FeaturesCardImg>
                                            <h5>{item.ProductsCardHeading}</h5>
                                            <p>{item.ProductsCardPara}</p>
                                        </FeaturesCard>
                                        </Fade>
                                    </Col>
                                })
                            }
                            </Row>
                        </FeaturesRight>
                    </Col>
                </FeaturesRow>
            </Container>
        </FeaturesSection>

    );
}

export default Products;
