import React from 'react';
import Slider from "react-slick";
import BannerSectionCustom from '../Banner';
import styled from 'styled-components';
import './custom.css';


const SliderBanner  = ({data}) => {

  const DivNext = styled.div`
    display: block;
    right: 2%;
    z-index: 1;

    
    :before{
      font-size: 30px
    }
  `;

  const DivPrev = styled.div`
  display: block;
  left: 2%;
  z-index: 1;

  
  :before{
    font-size: 30px
  }
`;

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <DivNext
        className={className}
        onClick={onClick}
      />
    );
  }
  
  
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <DivPrev
        className={className}
        onClick={onClick}
      />
    );
  }
  

  var settings = {
    arrows: data.Arrows,
    autoplay: data.AutoPlay,
    autoplaySpeed: data.AutoPlaySpeedMs,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  
    return (
        <div  id="simpleBannerSliderContainer">
          <Slider {...settings}>
            

            {
                data.Items.map((item,idx) => {
                  return(<BannerSectionCustom data={item}/>)
                })
            }

          </Slider>
      </div>
    );
}
export default SliderBanner;
