import styled from 'styled-components';
import {device} from '../Common/device';
import {Commonbtn,SectionHeading} from '../Common/common.style';
import {Check} from'@styled-icons/fa-solid/Check';
import {Row} from 'react-bootstrap';

export const KeyFeaturesSection = styled.section`
    padding:100px 0px;
    background-repeat: no-repeat;
    background-size: 800px;
    background-position: 700px 90px;

    @media ${device.tablet} {
        padding:80px 10px;
    }
`;

export const KeyFeaturesHeadingWrapper = styled.div`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }

    h6{
        text-align:center;
        color:#565759;
        font-weight:400;
        margin-bottom:0px;
    }
`;

export const KeyFeaturesHeading = styled(SectionHeading)`
`;

export const KeyFeaturesInner = styled.div`
`;

export const CustomRow = styled(Row)`
    align-items:center;
`;

export const KeyFeaturesLeft = styled.div`
    margin-right:30px;

    @media ${device.laptop} {
        margin-right:0px;
    }

    .KeyFeaturesLeftImg {
        box-shadow:0px 0px 26px 5px #d0d0d0;
        border-radius:10px;
    }
`;

export const KeyFeaturesRight = styled.div`
    margin-left:30px;

    @media ${device.laptop} {
        margin-top: 40px;
        margin-left:0px;
    }
`;

export const KeyFeaturesRightUl = styled.ul`
    margin-bottom:40px;
    margin-left:0px;
`;

export const KeyFeaturesRightLi = styled.li`
    margin-bottom:30px;
    list-style:none;
    display:flex;
`;

export const KeyFeaturesLiImgCol = styled.div`
    flex-shrink:0;
`;

export const CheckIcon = styled(Check)`
    width: 35px;
    height: 35px;
    padding: 10px;
    color: #6028fa;
    background: #fff;
    border-radius: 100%;
    box-shadow: 1px 3px 4px 1px #e6e6e6;
`;

export const KeyFeaturesLiContentCol = styled.div`
    margin-left:20px;

    h6{
        text-align:left;
        margin-bottom:10px;
    }
    p{
        
    }
`;





export const KeyFeaturesRightBtn = styled(Commonbtn)`
`;
