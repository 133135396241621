import React from 'react';
import {KeyFeaturesSection,KeyFeaturesHeading,KeyFeaturesInner,KeyFeaturesRightLi,KeyFeaturesHeadingWrapper,KeyFeaturesLiImgCol,CheckIcon,KeyFeaturesLiContentCol} from './keyfeatures.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';

const KeyFeatures = ({data}) => {
    const color = data.BackgroundGrey ? "#f9fafc" : "#FFFFFF";


    return (
        <KeyFeaturesSection id="KeyFeaturesContainer" style={{background: color}}>
            <Container>
                <Row>
                    <Col md="12">
                        <KeyFeaturesHeadingWrapper>
                            <Fade bottom delay={1*data.DelayConstant}>
                            <KeyFeaturesHeading>{data.KeyFeaturesHeading}</KeyFeaturesHeading>
                            </Fade>
                            <Fade bottom delay={2*data.DelayConstant}>
                            <h6>{data.KeyFeaturesPara}</h6>
                            </Fade>
                        </KeyFeaturesHeadingWrapper>
                    </Col>
                </Row>
                <KeyFeaturesInner className='row'>
                        
                                {
                                    data.KeyFeaturesItems.map((item,idx) => {
                                        return  <Col md="6">
                                        <KeyFeaturesRightLi>
                                            <Fade right delay={(idx+1)*data.DelayConstant}>
                                                <KeyFeaturesLiImgCol>
                                                    <CheckIcon/>
                                                </KeyFeaturesLiImgCol>
                                                <KeyFeaturesLiContentCol>
                                                    <h6>{item.KeyFeaturesTitle}</h6>
                                                    <p>{item.KeyFeaturesDescription}</p>
                                                </KeyFeaturesLiContentCol>
                                            </Fade>
                                        </KeyFeaturesRightLi>
                                    </Col>
                                        

                                    })
                                }
                                
                </KeyFeaturesInner>
            </Container>
        </KeyFeaturesSection>
    );
}

export default KeyFeatures;
