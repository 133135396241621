import React from 'react';
import {PerformanceSection,PerformanceHeadingWrapper,PerformanceSectionHeading,
    PerformanceHolder,PerformanceCountLayout
} from './performance.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';

const Performance =({data}) => {

    return (
        <PerformanceSection id="performanceContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <PerformanceHeadingWrapper>
                            <Fade bottom delay={1*data.DelayConstant}>
                                <PerformanceSectionHeading>{data.PerformanceSectionHeading}</PerformanceSectionHeading>
                            </Fade>
                            <Fade bottom delay={2*data.DelayConstant}>
                                <p>
                                {data.PerformanceHeadingPara}
                                </p>
                            </Fade>
                        </PerformanceHeadingWrapper>
                    </Col>
                </Row>
                <Row>
                {
                    data.Row.map((item,idx) => {
                    return <Col md="3" sm="6">
                        <Fade bottom delay={(idx+1)*data.DelayConstant}>
                            <PerformanceHolder>
                                <PerformanceCountLayout>
                                    <h2>{item.PerformanceCount}</h2>
                                </PerformanceCountLayout>
                                <p>
                                    {item.PerformanceDetails}
                                </p>
                            </PerformanceHolder>
                        </Fade>
                    </Col>
                    })
                }
                </Row>
            </Container>
        </PerformanceSection>
    );
}

export default Performance;
