import React, { useState } from "react";
import FooterCustom from '../containers/CustomContainers/Footer';
import HeadermenuCustom from '../containers/CustomContainers/HeadermenuBs';
import Products from '../containers/CustomContainers/Products';
import GlobalStyle from "../containers/CustomContainers/Common/global-styles";
import SliderBanner from "../containers/CustomContainers/SliderBanner";
import Performance from "../containers/CustomContainers/Performance";
import KeyFeatures from "../containers/CustomContainers/KeyFeatures";
import CookieContainer from "../containers/CustomContainers/CookieContainer";
import ContactPoc  from '../containers/CustomContainers/Contact';
import "../components/layout.css";
import Seo from "../components/seo"
import Fonts from "../containers/CustomContainers/Common/fonts"
import {  graphql } from 'gatsby';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import Partners from '../containers/CustomContainers/Partners'


const IndexPage = ({data}) => {

    const AppName = "PowerBI Tiles Suite";
    const SharedData  = data.sharedJson;
    const homepageJson = data.homepageJson;
    const [blocking, setBlocking] = useState(false);
    const [expanded, setExpanded] = useState(false);

    const partners = data.allPowerBiTilesPowerBiTilesPartnersListItem.nodes.map(item => {
        const content = JSON.parse(item.internal.content)
        if (content && content.fields && content.fields.Logo) {
            content.fields.Logo = JSON.parse(content.fields.Logo)
        }
        return content
    })

    //console.log(partners)

    return (
        <BlockUi tag="div" blocking={blocking} message="Submiting your message" keepInView>

            <div>
                <Fonts />
                    <GlobalStyle />
                    <HeadermenuCustom data={homepageJson.Header} expanded={expanded}  expandToggle={(newValue) => setExpanded(newValue)} />
                    <main onClick={() => setExpanded(false) }>
                        <Seo
                            title={homepageJson.SEO.Title}
                            description={homepageJson.SEO.Description}
                            favIconPath={homepageJson.SEO.FavIconPath}
                            previewImage={homepageJson.SEO.PreviewImage}
                            keywords={homepageJson.SEO.Keywords}

                        />
                        <SliderBanner data={homepageJson.BannerSlider}/>
                        <Products sharedData={SharedData.Products} data={homepageJson.Products} />


                        <Performance data={homepageJson.Performance}/>

                        <KeyFeatures data={homepageJson.KeyFeatures}/>


                        <ContactPoc sharedData={SharedData.ContactUs}  data={homepageJson.ContactUs} blockUIChange={(newValue) => setBlocking(newValue)} appName={AppName}  />

                        <Partners data={partners}/>
                    </main>
                    <CookieContainer sharedData={SharedData.Cookie} />

                    <FooterCustom data={homepageJson.Footer}/>
            </div>
        </BlockUi>

   )

}

export const pageQuery  = graphql`
query indexPageQuery {
    allPowerBiTilesPowerBiTilesPartnersListItem(
        sort: {order: ASC, fields: [data___fields___ColumnOrder]}
    ) {
        nodes {
            internal {
                content
            }
        }
    }
    
    sharedJson {
        Products {
            Items{
                ProductsCardIcon
                ProductsCardHeading
                ProductsCardPara
                ProductRoute
            }
        }
        ContactUs{
            SectionHeading
            SectionSubHeading
            ContactRight{
                ContactRightHeading
                ContactRightPara
            }
        }
        Cookie{
            CookieName
            CookieExpireDays
            SaveCookieOnDecline
            OkButtonMessage
            CookieMessage
        }
    }
    homepageJson{
            SEO {
                Title
                Description
                FavIconPath
                PreviewImage
                Keywords
            }
            Header{
                BrandLogo
                BrandLogoSticky
                Items
                MenuItems{
                    Menu
                    Href
                    LocalLink
                }
            }
            BannerSlider{
                Arrows
                AutoPlay
                AutoPlaySpeedMs
                Items {
                    BannerHeading
                    BannerDescription
                    WatchVideoAnchor
                    BtnText {
                        Title
                        Items {
                          Name
                          Href
                        }
                        MultiSelection
                    }
                    BtnText2 {
                        Title
                        Items {
                          Name
                          Href
                        }
                        MultiSelection
                    }
                    PathToImage
                }
            }
            Products {
                BackgroundGrey
                DelayConstant
                ProductsLeftHeading
                ProductsLeftPara
            }
            Performance {
                DelayConstant
                PerformanceSectionHeading
                PerformanceHeadingPara
                Row{
                    PerformanceCount
                    PerformanceDetails
                }
            }
            KeyFeatures {
                BackgroundGrey
                DelayConstant
                KeyFeaturesHeading
                KeyFeaturesPara
                KeyFeaturesItems {
                    KeyFeaturesTitle
                    KeyFeaturesDescription
                }
            }
            Footer {
                FooterLogo
                Items
                FooterCol2{
                    FooterTitle
                    FooterUl{
                        FooterMenu
                        Href
                        LocalLink
                    }
                }
                FooterCol3{
                    FooterTitle
                    FooterUl{
                        FooterMenu
                        Href
                    }
                }
                FooterCol4{
                    FooterTitle
                    FooterAddWrapper{
                        FooterAddImg
                        FooterAddText
                    }
                }
                BottomFooterPara
                BottomFooterName
                BottomFooterLink
                SocialLinks{
                    FacebookLink
                    TwitterLink
                    LinkedinLink
                    InstagramLink
                }
            }
            ContactUs {
                BackgroundGrey
            }
        }
    }
`;

export default IndexPage;
