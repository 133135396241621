import styled from 'styled-components';
import {SectionHeading} from '../Common/common.style';
import {device} from '../Common/device';
import BackgroundImg from '../../../../static/shared/banner/skills-section-banner.jpg'

export const PerformanceSection = styled.section`
    padding:100px 0px 70px;
    background-image: url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size:cover;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const PerformanceHeadingWrapper = styled.div`
    margin-bottom:75px;

    @media ${device.tablet} {
        margin-bottom:60px;
    }

    p{
        color:#fff;
        text-align:center;
        margin-bottom: 26px;
        font-size: 16px;
    }
`;

export const PerformanceSectionHeading = styled(SectionHeading)`
    color:#fff;
`;



export const PerformanceHolder = styled.div`
    text-align:center;
    margin-bottom:30px;

    p{
        color:#fff;
        margin-bottom:0px;
        line-height:1;
        font-size:18px;
    }
`;

export const PerformanceCountLayout = styled.div`
    background: rgb(8, 8, 8,.5);
    border-radius:10px;
    color:#fff;
    max-width:120px;
    height:80px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin:0px auto 20px;

    h2{
        color:#fff;
        font-weight:700;
        margin-bottom:0px;
    }
`;
